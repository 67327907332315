body {
  margin: 0;
  font-family: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0c0c27;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-darkblue {
  background-color: #0c0c27;
  background-color: #060637;
}

.table-vcenter thead th {
  vertical-align: middle;
}
.table-vcenter td {
  vertical-align: middle;
}

@media (min-width: 660px) {
  .minwidth-640 {
    min-width: 640px !important;
  }
}
.table a,
.card a {
  color: #212529;
}

.pointer {
  cursor: pointer;
}
html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100vw;
  padding-top: 61px;
}
